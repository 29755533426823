<template>
  <ContainerHub class="hub-contacts">

    <!-- Slot Header -->

    <template slot="header">
      <HubHeader
        :titleTag="'h1'"
        :title="title"
        :email="email"
        :description="description"
      />
      <TheToolsContact />
    </template>

    <!-- Slot Body -->

    <template slot="body">
      <ElementTable>
        <template slot="header">
          <UserTableHeader />
        </template>
        <template slot="body">
          <UserTableBody
            :content="item"
            :key="`user-${index}`"
            v-for="(item, index) in items" />
        </template>
      </ElementTable>
    </template>

    <!-- Slot Footer -->

    <template slot="footer">
      <BaseButton
        icon="more"
        icon-position="right"
        color="primary"
        v-if="offset < total"
        @click.native="handleClick">
          {{ $t(`message.contact.list.button.more.text`) }}
      </BaseButton>
    </template>
  </ContainerHub>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import BaseButton from '@base/BaseButton'
import ContainerHub from '@containers/ContainerHub'
import HubHeader from './HubHeader'
import ElementTable from '@elements/ElementTable'
import UserTableHeader from '@blocks/user/UserTableHeader'
import UserTableBody from '@blocks/user/UserTableBody'
import TheToolsContact from '@blocks/tools/TheToolsContact'

export default {
  name: 'HubContactsList',
  props: {
    name: {
      type: String,
      required: true
    }
  },
  components: {
    BaseButton,
    HubHeader,
    TheToolsContact,
    ElementTable,
    UserTableHeader,
    UserTableBody,
    ContainerHub
  },
  data () {
    return {}
  },
  computed: {
    ...mapGetters({
      title: 'page/getTitle',
      description: 'page/getDescription',
      email: 'page/getEmail',
      items: 'users/getItems',
      selected: 'users/getSelected',
      total: 'users/getTotal',
      offset: 'users/getOffset'
    }),
    displayType () {
      return 'large'
    }
  },
  watch: {
    items: {
      handler (val, oldVal) {
        this.updateSelected()
      },
      deep: true
    }
  },
  methods: {
    ...mapActions({
      fetchGetAddUsers: 'page/fetchGetAddUsers',
      updateSelected: 'users/updateSelected'
    }),
    handleClick () {
      this.fetchGetAddUsers()
    }
  }
}
</script>

<style lang="scss">
  .hub-contacts{
    @media print {
      .row__group--main{
        border-left: 1px solid colors("border");
      }
    }
  }
</style>
