<template>
  <TheTools class="tools-contact">
    <template slot="nav-left">
      <TheToolsButton
        :text="'Search'"
        :icon="'search'"
        :type="'trigger'"
        :active="current === 'search'"
        @click="handleSearch"
        v-if="isDesktop"
      />
      <TheToolsButton
        :text="'Filters'"
        :icon="'filters'"
        :type="'trigger'"
        :active="current === 'filters'"
        @click="handleFilters"
        v-if="isDesktop"
      />
      <TheToolsButton
        :text="'Export all'"
        :icon="'export2'"
        :tag="total"
        @click="handleExportAll"
        v-if="isDesktop"
      />
      <TheToolsButton
        :text="'Export list'"
        :icon="'export2'"
        :tag="selectedLength"
        @click="handleExport"
        v-if="isDesktop"
      />
      <TheToolsButton
        :text="'Send mail to selected'"
        :icon="'export'"
        :tag="selectedLength"
        @click="handleSend"
        v-if="isDesktop"
      />
    </template>
    <template slot="nav-right">
      <TheToolsCounter :count="total" />
    </template>
    <template slot="content">
      <TheToolsDropdown
        :type="'search'"
        :trigger="searchTrigger"
        :force-active="current === 'search'"
      />
      <TheToolsDropdown
        :type="'filters'"
        :filtersType="'contact'"
        :trigger="filtersTrigger"
        :force-active="current === 'filters'"
      />
      <TheToolsButton :text="'Export all'" :icon="'export2'" :tag="total" @click="handleExportAll" v-if="!isDesktop" />
      <TheToolsButton :text="'Export list'" :icon="'export2'" :tag="selectedLength" @click="handleExport" v-if="!isDesktop" />
      <TheToolsButton :text="'Send mail to selected'" :icon="'export'" :tag="selectedLength" @click="handleSend" v-if="!isDesktop" />
    </template>
  </TheTools>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import TheTools from './TheTools'
import TheToolsButton from './TheToolsButton'
import TheToolsDropdown from './TheToolsDropdown'
import TheToolsCounter from './TheToolsCounter'

export default {
  name: 'TheToolsContact',
  components: {
    TheTools,
    TheToolsButton,
    TheToolsCounter,
    TheToolsDropdown
  },
  data () {
    return {
      name: 'contact',
      searchTrigger: {
        text: 'Search',
        icon: 'search'
      },
      filtersTrigger: {
        text: 'Filters',
        icon: 'filters'
      },
      legendTrigger: {
        text: 'Legend',
        icon: 'info'
      },
      current: ''
    }
  },
  computed: {
    ...mapGetters({
      device: 'breakpoints/getDevice',
      selected: 'users/getSelected',
      total: 'users/getTotal',
      emailMailto: 'users/getEmailMailto'
    }),
    isDesktop () {
      return this.device === 'desktop'
    },
    selectedLength () {
      return this.selected.length
    }
  },
  watch: {
    selected () {
      this.updateEmailList()
    }
  },
  methods: {
    ...mapActions({
      postSelectedUsers: 'users/postSelectedUsers',
      postAllUsers: 'users/postAllUsers',
      updateName: 'modal/updateName',
      updateEmailList: 'users/updateEmailList',
      updateSelected: 'users/updateSelected'
    }),
    handleSearch (status) {
      this.current = status ? 'search' : ''
    },
    handleFilters (status) {
      this.current = status ? 'filters' : ''
    },
    handleExport (status) {
      this.current = status ? 'export' : ''
      this.postSelectedUsers()
    },
    handleExportAll (status) {
      this.current = status ? 'export' : ''
      this.postAllUsers()
    },
    handleSend (status) {
      this.current = status ? 'send' : ''
      const maxLength = 2000

      if (this.emailMailto.length > maxLength) this.updateName('send-email')
      else window.location.href = this.emailMailto
    }
  }
}
</script>

<style lang="scss">
.tools-contact{

}
</style>
