<template>
  <div class="contacts-list-view" v-if="loaded">
    <HubContactsList name="contact-list" v-if="showList" />
    <router-view v-else />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import HubContactsList from '@blocks/hubs/HubContactsList'
import updateBreadcrumb from '@mixins/updateBreadcrumb'

export default {
  name: 'ContactsListView',
  mixins: [ updateBreadcrumb ],
  components: {
    HubContactsList
  },
  data () {
    return {
      showList: false,
      loaded: false
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.switchViews(to)
    })
  },
  beforeRouteUpdate (to, from, next) {
    this.switchViews(to)
    next()
  },
  methods: {
    ...mapActions({
      isReady: 'page/getIsReady',
      fetchGetPage: 'page/fetchGetPage',
      fetchData: 'filters/fetchData'
    }),
    switchViews (to) {
      this.loaded = false
      this.showList = !to.params.userId
      const service = this.showList ? 'contact-list' : 'contact-detail'
      const params = this.showList ? {} : { userId: to.params.userId }
      this.fetchGetPage({ service, params }).then(() => {
        if (this.showList) {
          this.fetchData({ route: to.name, context: 'view' }).then(() => {
            this.loaded = true
          })
        } else {
          this.loaded = true
        }
      })
    }
  }
}
</script>
